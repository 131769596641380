import anime from 'animejs';
import { graphql, Link, navigate } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React, { useContext } from 'react';
import { useCallback } from 'react';
import { useEffect, useState } from 'react';
import AnimeLink from '../components/AnimeLink';
import Head from '../components/Head';
import Header from '../components/Header';

const ProfilePage: React.VFC<{
  data: any;
  location: Location;
}> = ({ data, location }) => {
  const [profileImages] = useState<string[]>([
    '../images/icon-01.png',
    '../images/icon-02.png',
  ]);

  const [profileImage, setProfileImage] = useState<string>(
    '../images/icon-01.png'
  );

  const randomProfile = useCallback(() => {
    const prevImage = profileImage;
    const list = profileImages.filter((value) => prevImage !== value);
    setProfileImage(list[~~(Math.random() * list.length)]);
  }, [profileImage, profileImages]);

  return (
    <>
      <Head title="Profile" description="totorajのプロフィール" />
      <main className="body">
        <article>
          <header>
            <h1>Profile</h1>
            <div className="profile-totoraj">
              <img
                src={profileImage}
                alt="プロフィール画像"
                onClick={randomProfile}
              />
              <h2 className="name">
                Reona Oshima <span>(totoraj)</span>
              </h2>
            </div>
          </header>
          <section className="article-body">
            <h2>自己紹介</h2>
            <p>
              自分が「欲しい」「使いたい」「面白い」と思えるものをつくるのが好きです。
            </p>
            <p>
              2014年ごろから「HTML/CSS/JavaScript」などを使って色々つくり続けて今に至ります。
            </p>
            <p>
              みる人、使う人にわかりやすいシンプルなデザインを心がけています。
            </p>
            <p>
              フロントエンド側で完結するものを作ることが多いですが、場合によってはサーバーサイドまで書きます。
            </p>
            <p>
              制作物はフルスクラッチのものがほとんどでしたが、最近はVue.jsやReactを触っています。
            </p>

            <h2>基本情報</h2>

            <h3>誕生日</h3>
            <p>1999/09/30</p>

            <h3>性別</h3>
            <p>♂</p>

            <h3>住んでるところ</h3>
            <p>Hirakata, Osaka, Japan</p>

            <h3>職業</h3>
            <p>ニート(お仕事探し中)</p>

            <h2>できること</h2>

            <h3>得意</h3>
            <ul>
              <li>HTML</li>
              <li>CSS</li>
              <li>JavaScript</li>
              <li>Node.js</li>
            </ul>

            <h3>使える</h3>
            <ul>
              <li>TypeScript</li>
              <li>React</li>
              <li>Vue.js</li>
            </ul>

            <h3>練習中</h3>
            <ul>
              <li>Git (基本1人での開発のため触れる機会があまりなかった)</li>
            </ul>

            <h2>趣味</h2>
            <ul>
              <li>お絵描き(主にクリスタを使用)</li>
              <li>動画制作(主にAviUtlを使用)</li>
              <li>ゲーム(Apex, LoL, グラブルなど)</li>
              <li>アニメ鑑賞</li>
            </ul>

            <h2>各種リンク</h2>
            <ul>
              <li>
                Twitter:{' '}
                <a href="https://twitter.com/totoraj_game">@totoraj_game</a>
              </li>
              <li>
                GitHub: <a href="https://github.com/totoraj930">totoraj930</a>
              </li>
              <li>
                Pixiv: <a href="https://www.pixiv.net/users/7235355">7235355</a>
              </li>
              <li>
                Twitch:{' '}
                <a href="https://www.twitch.tv/totoraj930">totoraj930</a>
              </li>
              <li>
                YouTube:{' '}
                <a href="https://www.youtube.com/channel/UCxqaEngWUWEOiIecETprfTQ">
                  totoraj
                </a>
              </li>
              <li>
                Blog: <a href="https://blog.totoraj.net/">totorajの開発日記</a>
              </li>
            </ul>
          </section>
        </article>
      </main>
    </>
  );
};

export default ProfilePage;
